<script>
import Layout from '../../layouts/main';
import { api } from '@/state/services';
import { VclList } from 'vue-content-loading';
import Stat from '@/components/widgets/stat';

export default {
  locales: {
    pt: {
      'Graduation': 'Graduação',
      'Points': 'Pontos',
      'Premiation': 'Premiação',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'Graduation': 'Patrocinio',
      'Points': 'Puntos',
      'Premiation': 'Premiación',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: { Layout, VclList, Stat },
  data() {
    return {
      table: {
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },

      statData: null,
    };
  },
  methods: {
    getList() {
      this.table.body = null

      this.table.loading = true
      this.table.errored = false
      this.table.empty = false

      api
        .get('report/graduation')
        .then(response => {
          if (response.data.status=='success') {
            this.statData = [
              {
                icon: "bx bx-diamond",
                title: "Graduation",
                value: response.data.graduation.name,
              },
              {
                icon: "bx bx-dollar",
                title: "Points",
                value: response.data.graduation.points
              }
            ]

            this.table.body = response.data.list
          }
        })
        .catch(error => {
          this.table.errored = error
        })
        .finally(() => {
          this.table.loading = false
          if (this.table.body=='' || this.table.body==null) {
            this.table.empty = true
          }
        })
    }
  },
  mounted() {
    this.getList()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Graduation') }}</h4>
          <div class="page-title-right">
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-6">
        <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" :image="stat.image" />
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div v-if="table.errored">
          <div class="card">
            <div class="card-body">
              {{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}
            </div>
          </div>
        </div>
        <div v-else-if="table.empty">
          <div class="card">
            <div class="card-body">
              {{ t('No records found.') }}
            </div>
          </div>
        </div>
        <div v-else>
          <div class="card">
            <div class="card-body">
              <div v-if="table.errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
              <div v-else-if="table.empty">{{ t('No records found.') }}</div>
              <div v-else class="table-responsive">
                <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
                <table v-else class="table table-centered table-nowrap text-center">
                  <thead class="thead-light">
                    <tr>
                      <th>{{ t('Graduation') }}</th>
                      <th>{{ t('Points') }}</th>
                      <th>{{ t('Premiation') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(td,index) in table.body" :key="index">
                      <td>{{ td.name }}</td>
                      <td>{{ td.points }}</td>
                      <td>{{ td.premiation }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>